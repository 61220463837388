<template>
  <div class="transferClue">分配线索</div>
</template>
<script>
export default {
  data() {
    return {
      distributionIds: [],
    }
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem('distributionIds')
    next()
  },
  computed: {},
  watch: {},
  mounted() {
    this.distributionIds = []
    this.distributionIds = sessionStorage.getItem('distributionIds') ? JSON.parse(sessionStorage.getItem('distributionIds')) : []
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.transferClue{}
</style>